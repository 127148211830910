import { notification } from "antd"
import { getPushPathWrapper } from "../routes"
import { RupeeSymbol } from "../settings"
import sound from '../assets/Notification.mp4'
const initialState = {
  notificationCount: 0,
  notifications: []
}
function goToOrder(id, dispatch) {
  dispatch(getPushPathWrapper('orderDetails', { id }))
}
export default (state = initialState, action) => {
  console.log(action,'notificationAction')

  switch (action.type) {

    case 'SHOW_NOTIFICATION':
      let audio = new Audio(sound);
      audio.play()
      // notification.open({
      //   style: { backgroundColor: '#75e23f', cursor: 'pointer' },
      //   onClick: () => {
      //     goToOrder(action.payload?.orderId?.orderId, action.dispatch)
      //     notification.destroy()
      //   },
      //   placement: 'bottomRight',
      //   message: `New Order (#${action.payload?.orderId?.orderId})`,
      //   description: `Order of ${RupeeSymbol}${action.payload?.orderId?.grandTotal} Received.`
      // })
      let newNotification = [...state.notifications, action.payload]
      return {
        ...state,
        notifications: newNotification,
        notificationCount: newNotification.length
      }

    case 'CLEAR_NOTIFICATION':
      return {
        ...state,
        notifications: [],
        notificationCount: 0
      }

    default:
      return state
  }
}
