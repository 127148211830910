import { Result, Button, Skeleton } from "antd";
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import menuData from "../../routes";
import { componentLoader } from "../../utils";
import Media from "react-media";
import WebpImage from "../../assets/mobilePlaceholder.webp";
import request from "../../request";
import { useDispatch } from "react-redux";
import DeviceIdentifier from "react-device-identifier";
const AuthLayout = lazy(() =>
  componentLoader(() => import("../../layouts/AuthLayout"))
);
const BasicLayout = lazy(() =>
  componentLoader(() => import("../../layouts/basicLayout"))
);
const Login = lazy(() =>
  componentLoader(() => import("../authentication/login"))
);
const Signup = lazy(() =>
  componentLoader(() => import("../authentication/signup"))
);
const SignupDetails = lazy(() =>
  componentLoader(() => import("../authentication/signupDetails"))
);
const OTP = lazy(() => componentLoader(() => import("../authentication/otp")));
const ForgetPin = lazy(() =>
  componentLoader(() => import("../authentication/forget"))
);
const NotFound = lazy(() =>
  componentLoader(() => import("../../components/Exception/404"))
);
const NewHome = lazy(() =>
  componentLoader(() => import("../../containers/newhomepage/index"))
);
const NewHomeMobile = lazy(() =>
  componentLoader(() => import("../../containers/newhomepageMobile/index"))
);
const NewHomeTablet = lazy(() =>
  componentLoader(() => import("../../containers/newhomepageTablet/index"))
);
const Exp = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <NotFound />
  </div>
);
export default (props) => {
  // useEffect(() => {
  //     window.addEventListener('unload', handleTabClosing)
  //     return () => {
  //         window.removeEventListener('unload', handleTabClosing)

  //     }

  // })

  // const handleTabClosing = async (e) => {
  //     request
  //         .logout()
  //         .then(({ error, data }) => {
  //         });
  // }
  return (
    <Media queries={{ small: { maxWidth: 950 } }}>
      {(matches) => (
        <Suspense
          fallback={
            <div style={{ padding: "2rem" }}>
              <Skeleton active />
            </div>
          }
        >
          <Switch>
            {menuData.map((item, key) => {
              if (!item.children) {
                return (
                  <Route
                    exact
                    path={item.path}
                    key={item.path}
                    render={(route) => {
                      return (
                        <BasicLayout
                          isMobile={matches.small}
                          path={item.path}
                          menuData={menuData}
                          item={item}
                        >
                          {!!item.component ? (
                            <Suspense
                              fallback={
                                <div>
                                  <Skeleton active />
                                </div>
                              }
                            >
                              {item.component}
                            </Suspense>
                          ) : (
                            <></>
                          )}
                        </BasicLayout>
                      );
                    }}
                  />
                );
              }
            })}

            {menuData.map((item, key) => {
              if (item.children) {
                return item.children.map((child, k) => {
                  return (
                    <Route
                      exact
                      path={child.path}
                      key={child.path}
                      render={(route) => {
                        return (
                          <BasicLayout
                            isMobile={matches.small}
                            path={child.path}
                            menuData={menuData}
                            item={item}
                          >
                            {!!child.component ? (
                              <Suspense
                                fallback={
                                  <div>
                                    <Skeleton active />
                                  </div>
                                }
                              >
                                {child.component}
                              </Suspense>
                            ) : (
                              <></>
                            )}
                          </BasicLayout>
                        );
                      }}
                    />
                  );
                });
              }
            })}

            <Route
              exact
              path={"/"}
              key={"login"}
              render={(route) => (
                <>
                  <DeviceIdentifier isDesktop={true}>
                    <NewHome />
                  </DeviceIdentifier>
                  <DeviceIdentifier isTablet={true}>
                    <NewHomeTablet />
                  </DeviceIdentifier>
                  <DeviceIdentifier isMobile={true}>
                    <NewHomeMobile />
                  </DeviceIdentifier>
                </>
              )}
            />
            <Route
              exact
              path={"/login"}
              key={"login"}
              render={(route) => (
                <AuthLayout>
                  <Login />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path={"/signup"}
              key={"signup"}
              render={(route) => (
                <AuthLayout>
                  <Signup />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path={"/accounDetails"}
              key={"accounDetails"}
              render={(route) => (
                <AuthLayout>
                  <SignupDetails />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path={"/verify"}
              key={"otp"}
              render={(route) => (
                <AuthLayout>
                  <OTP />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path={"/forget"}
              key={"forgetPin"}
              render={(route) => (
                <AuthLayout>
                  <ForgetPin />
                </AuthLayout>
              )}
            />
            <Route render={(route) => <Exp />} />
          </Switch>
        </Suspense>
      )}
    </Media>
  );
};
