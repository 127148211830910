import { notification } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { apiUrl, customerPortalUrl, DateFormat } from './settings'
import { store } from './store'


let authAxios = axios.create({
    baseURL: apiUrl
})

let authAxios1 = axios.create({
    baseURL: apiUrl,

})

const range = localStorage.getItem('dateRange')

console.log(range, 'range1')

authAxios1.interceptors.request.use(function (request) {
    // Do something before request is sent
    request.responseType = 'blob'
    return request;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

//   authAxios1.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     // config.data ='{"dateRange":"2022-06-01,2022-06-11"}'
//     config.params={count: undefined,
//         // dateRange: localStorage.getItem('dateRange')}
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

export const getToken = () => {
    return {
        headers: {
            Authorization: 'Token ' + localStorage.getItem('token')
        }
        // headers: { Authorization: 'Token ' + 'c38602aa24f9850ac02598cb109ac909b8870fa7' }
    }
}

export const storeUrl = (storeId, productCategoryId, productId) => {
    if (!productCategoryId && !productId && storeId)
        return `${customerPortalUrl}/${storeId}`
    else if (productCategoryId && !productId && storeId)
        return `${customerPortalUrl}/${storeId}/${btoa(productCategoryId)}`
    else
        return `${customerPortalUrl}/${storeId}/${btoa((productCategoryId + '/' + productId).toString())}`
}
export const storeMessage = (shop, url) => encodeURIComponent(
    `Hello !\nWe are now online too and taking orders with delivery to your doorsteps.\n\nCheckout our Online store link: ${url || storeUrl(shop?.storeId)}\n\nFeel free to call us on ${shop?.mobile} if you need any help with ordering online.\nThank you !\n${shop?.storeName}`
)
export const couponMessage = (shop, coupon) => encodeURIComponent(
    `Coupon Code: *${coupon.couponCode}*\n${coupon.discountAmount}${coupon.discountType === 'PERCENTAGE' ? '%' : ''} off on first ${coupon.usesPerCustomer} orders per user\n${storeUrl(shop?.storeId)}\n\nFeel free to call us on ${shop?.mobile} if you need any help with ordering online.\nThank you !\n${shop?.storeName}`
)
export const fileUploadUrl = `${apiUrl}/api/file-upload/image/`
export const productImageUrl = `${apiUrl}/media/`
export const imagePathUrl = (path) => `${apiUrl}/assets/${path}`
export const businessCatImagePathUrl = (path) => `${apiUrl}/media/${path}`
export const productBulkUrl = `${apiUrl}/api/product/add/bulk/`
export const productBulkSampleUrl = `${apiUrl}/api/file-upload/sample-file/get/?filename=SampleFile`

class Request {

    error = err => {
        try {
            if (err.response.status === 401 || err.response.status === 403) {
                store.dispatch({ type: 'LOGOUT' })
                localStorage.clear()
                window.location.reload()
            }
            else if (err.response.status === 429) {
                notification.destroy()
                notification.warning({
                    message: "Too many requests",
                    description: err?.detail
                })
            }
        } catch (e) {
        }
    }

    // ------------------------------------------- API Start from here --------------------------------------------- //

    deleteShopkeeper(data) {
        return new Promise((next, error) => {
            authAxios
                .delete('/api/deleteshopper/', getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    signup(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/shopkeeper/add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    logout(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/shopkeeper/logout/', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/shopkeeper/login/', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    sendOtp(data, params = {}, dispatch) {
        console.log(params.checkIfExist, 'params')

        return new Promise((next, error) => {
            authAxios
                .post('/api/get-otp/', data)
                .then(d => {
                    if (!d.data.error)
                        notification.success({
                            message: d.data?.message || 'OTP Sent Successfully'
                        })
                    if (d.data?.data?.otp) {
                        let time = moment().add(30, 'seconds').valueOf()
                        dispatch && dispatch({
                            type: 'SET_OTP_TIMER',
                            payload: time
                        })
                        if (process.env.NODE_ENV === 'development') {
                            notification.destroy()
                            notification.info({ message: 'OTP is ' + d.data?.data?.otp })
                        }
                    }
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    verifyOtp(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/verify-otp/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    VerifyEmail(data) {
        let obj={
            email: data
        }
        console.log(obj,'verifydata')

        return new Promise((next, error) => {
            authAxios
                .post('/api/verify/email/', obj, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    sendEmail(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/send-receipt/', {},getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    forgetPassword(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/forgetpassword', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // user api

    getProfile() {
        return new Promise((next, error) => {
            authAxios
                .post('/api/user/', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateProfile(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/shopkeeper/update/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getAddress() {
        return new Promise((next, error) => {
            authAxios
                .post('/api/shopkeeper/address/get/', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    addAddress(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/shopkeeper/address/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getGeoAddress({ lat, lng }) {
        let newObj = {
            lat: lat,
            lng: lng
        }
        return new Promise((next, error) => {
            authAxios
                .post(`/api/reverse-geocoding/token/`, newObj, getToken())
                .then(d => {
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getGeoAddressSignup({ lat, lng }) {

        return new Promise((next, error) => {
            authAxios
                .post(`/api/reverse-geocoding/token/`, { lat: lat, lng: lng }, getToken())
                .then(d => {
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    updateAddress(data) {
        return new Promise((next, error) => {
            authAxios
                .put('/api/shopkeeper/address/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // dashboard api

    getDashboardViewData(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/dashboard/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getGraphData(data, filters) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/graph/', { ...data, ...filters }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // products api

    addProduct(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product/add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    exportProduct(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/product/export/', { data, responseType: 'blob' }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Products_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateProduct(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/product/edit/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProductById(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/product/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProducts(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/products/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    addProductVariant(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product-variant/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProductVariants(data = {}) {
        return new Promise((next, error) => {
            authAxios
                .post('api/get-product-variant/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateProductVariant(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/product-variant/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    deleteProductVariant(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/product-variant/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    deleteProduct(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/product/delete/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }


    // orders api

    addOrder(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateOrder(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/status-update/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    console.log('order status update error', err)
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getOrdersIds(data = {}) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/ids/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getOrdersWithFilters(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/analytics/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getOrderById(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getDeliveryStatus(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/shopperbyid/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // products categories api

    getProductCategory(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product-category/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateProductCategory(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/product-category/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    deleteProductCategory(id, data) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/product-category/${id}/`, { data, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // business categories api

    getBusinessCategory(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/business-category-get/', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // coupons api

    addCoupon(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/post_coupon/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getCoupons(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/get_coupon/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateCoupon(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/put_coupon/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    deleteCoupon(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/put_coupon/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // bulk upload

    bulkUpload(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product/add/bulk/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    downlaodReport(data) {
        return new Promise((next, error) => {
            authAxios
            .get('/api/order/report/', { params: data, ...getToken(), responseType: 'blob' })
                // .post('/api/order/report/', { data, responseType: 'blob' },getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: `${data?.exportFormat === 'xls' ? 'application/vnd.ms-excel' : 'application/pdf'}` })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `OrderReport_(${data?.dateRange ? data?.dateRange?.replace(',', '_to_') : moment().format('DD-MM-YYYY_HH:mm_A')})).${data?.exportFormat}`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    contactUs(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/contact-us/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    //customers api

    getCustomers(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }


    getMarketingImages(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/file-upload/marketing/images/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // bank details

    addBankDetails(data, paymentDetailsType) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/bank-details/', data, { params: { paymentDetailsType }, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateBankDetails(data) {
        return new Promise((next, error) => {
            authAxios
                .put('/api/bank-details/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    deleteBankDetails(data = {}) {
        return new Promise((next, error) => {
            authAxios
                .delete('/api/bank-details/', { data, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getBankDetails(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/get-bank-details/', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getTransactions(data) {
        return new Promise((next, error) => {
            // .get('/api/transaction/', getToken())
            authAxios
                .post('/api/payout/data/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getBusinessTimings(data) {
        return new Promise((next, error) => {
            // .get('/api/transaction/', getToken())
            authAxios
                .post('/api/ordertimetable/get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateBusinessTiming(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/addlist/ordertimetable/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    validateIfsc(code) {
        return new Promise((next, error) => {
            authAxios
                .get(`https://ifsc.razorpay.com/${code}`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next(null)
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    validateUpi(vpa) {
        return new Promise((next, error) => {
            authAxios
                .post(`https://api.attestr.com/api/1/public/finanx/vpa`, { vpa })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next(null)
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    reviewRating(data) {
        console.log('contact us', data)
        return new Promise(async (next, error) => {

            authAxios
                .post('/api/rating/reviews/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    // ads request api
    adsRequest(data) {
        console.log('contact us', data)
        return new Promise(async (next, error) => {

            authAxios
                .post('/api/shop-campaign-request/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    addQrCode(data) {
        console.log('contact us', data)
        return new Promise(async (next, error) => {

            authAxios
                .post(`/api/qrcode/add/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getQrCode() {
        return new Promise(async (next, error) => {

            authAxios
                .post(`/api/qrcode/get/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    deleteQrCode() {
        return new Promise(async (next, error) => {

            authAxios
                .delete(`/api/qrcode/delete/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

 
    downloadBusinesscards(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/download-business-card/',{ data,responseType: 'blob' }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'image/jpeg' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `my-card.jpeg`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    downloadBannercards(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('api/download-store-flyers/',{ data,responseType: 'blob' }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'image/jpeg' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `my-card.jpeg`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    downloadWhatsappcards(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('api/download-whatsapp-story/',{ data,responseType: 'blob' }, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'image/jpeg' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `my-card.jpeg`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
}

export default new Request();