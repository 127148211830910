import { useEffect, useState, lazy } from 'react';
import { List, Switch, Spin, notification, Button, Space, Popover, Avatar, Tooltip, TimePicker, Form, DatePicker } from 'antd';
import { EditOutlined, MoreOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons'
// import InfiniteScroll from 'react-infinite-scroller';
import './styles.less'
import request from '../../request';
import { useDispatch, useSelector } from 'react-redux';
import { getPushPathWrapper } from '../../routes';
import findIndex from 'lodash/findIndex'
import moment from 'moment'
import { componentLoader, confirmBox } from '../../utils';
import { goBack } from 'connected-react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
const ShareModal = lazy(() => componentLoader(() => import('../shareModal')))


const BusinessHours = (props) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [state, setState] = useState({
        data: [],
        loading: false,
        showShare: false,
        showList: true,
        showCoupon: false,
        selectedCoupon: '',
        visibilityLoader: [],
        testData: []
    })
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))

    const { loading, showShare, visibilityLoader, testData, data } = state

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            loading: true
        }))
        request.getBusinessTimings().then((d) => {
            setState((prev) => ({
                ...prev,
                testData: _.reverse(d.data),
                loading: false
            }))
        }).catch((err) => {
            console.error('error time occur', err)
        })
    }, [])

    const onFinish = async (formData) => {
        let testDataClone = _.cloneDeep(testData)
        testDataClone = _.each(testData, (testVal, testKey) => {
            _.each(formData, (val, key) => {

                if (testVal?.id == key.split('_')[1]) {

                    if (key.split("_")[0] == "closeTime" || key.split("_")[0] == "openTime") {

                        testVal[key.split("_")[0]] = val.format('hh:mm A')
                    } else {
                        testVal[key.split("_")[0]] = val

                    }
                }



            })
        })

        setState((prev) => ({
            ...prev,
            loading: true
        }))
        let { error, success, message, data } = await request.updateBusinessTiming(testDataClone)
        setState((prev) => ({
            ...prev,
            loading: false
        }))
        if (error) {
            notification.warning({ message: message || 'Oops, Something Went Wrong' })
        }
        else if (success) {
            notification.success({ message: message || "Business Timings updated successfully" })
        }

    }
    const allOpenTimeChange = () => {
        _.each(form.getFieldsValue(), (val, key) => {

           if (key.split("_")[0] == "openTime") {

                form.setFieldsValue({ [`openTime_${key.split('_')[1]}`]: moment(form.getFieldValue('defaultOpenTime'), 'hh:mm A') })
            }
        })
    }
  const allCloseTimeChange = () =>{
    _.each(form.getFieldsValue(), (val, key) => {

        if (key.split("_")[0] == "closeTime") {

            form.setFieldsValue({ [`closeTime_${key.split('_')[1]}`]: moment(form.getFieldValue('defaultCloseTime'), 'hh:mm A') })
        }
    })
  }


    return (
        <div className="couponListWrapper">
            <Form form={form} onFinish={onFinish}>
                <ShareModal
                    isVisible={!!showShare}
                    onClose={() => { setState((prev) => ({ ...prev, showShare: false })) }}
                    currentUser={currentUser}
                    coupon={showShare}
                />
                <div style={{ display: "flex", gap: "2%" }}>
                    <div id="defaultStoreTime" style={{ display: "flex", gap: "4%" }}>
                        <h4 style={{ fontSize: '18px', marginBottom: 0, color:"rgba(0, 0, 0, 0.85)" }}>Set same time for every day</h4>
                        <Form.Item rules={[{
                            type: 'object'
                        }]} initialValue={moment('10:00:00', 'hh:mm A')} label="Opening Time" name='defaultOpenTime' style={{ marginBottom: "0px" }}>
                            <TimePicker allowClear={false} onOk={allOpenTimeChange}  format="hh:mm A" showNow={false} use12Hours bordered={true}
                            />
                        </Form.Item>
                        <Form.Item rules={[{
                            type: 'object'
                        }]} initialValue={moment('18:00:00', 'hh:mm A')} label="Closing Time" name='defaultCloseTime' style={{ marginBottom: "0px" }}>
                            <TimePicker allowClear={false} onOk={allCloseTimeChange}  format="hh:mm A" showNow={false} use12Hours bordered={true}
                            />
                        </Form.Item>
                    </div>
                </div>
                <List
                    loading={loading}
                    id='couponList'
                    grid={{ gutter: 20, column: 3 }}
                    dataSource={testData}
                    renderItem={(item, index) => {
                        return (
                            <List.Item key={item.id}>
                                <List.Item.Meta
                                    title={
                                        <div className="customTiming">
                                            <div className="customTimeSubItems">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar size={40} style={{ marginRight: 15, background: "#032176" }}>
                                                        {_.upperFirst(item.day.substring(0, 1))}
                                                    </Avatar>
                                                    <div>
                                                        <div className='couponListText'>
                                                            <h4 style={{ fontSize: '20px', marginBottom: 0, color:"rgba(0, 0, 0, 0.85)" }}>
                                                                {_.upperFirst(item.day)}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Form.Item valuePropName='checked' initialValue={item?.status} id={index} name={`status_${item?.id}`} key={item?.id} >

                                                    <Switch size='small' checked={item?.status}
                                                    />
                                                </Form.Item>

                                            </div>

                                            <div className="customTimeSubItems">
                                                <div className="opening">
                                                    <p className="customTimeText">Opening Time</p>
                                                    <div style={{ marginTop: "10px" }}>
                                                        <Form.Item rules={[{
                                                            type: 'object'
                                                        }]} initialValue={moment(item?.openTime, 'hh:mm:ss')} id={index} name={`openTime_${item?.id}`} key={item?.id} className='formItem'>
                                                            <TimePicker allowClear={false} format="hh:mm A" showNow={false} use12Hours bordered={false}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='timemid'>To</div>
                                                <div className="closing">
                                                    <p className="customTimeText">Closing Time</p>
                                                    <div style={{ marginTop: "10px" }}>
                                                        <Form.Item initialValue={moment(item?.closeTime, 'hh:mm:ss')} id={index} name={`closeTime_${item?.id}`} key={item?.id} className='formItem'>
                                                            <TimePicker allowClear={false} format="hh:mm A" showNow={false} use12Hours={true} bordered={false} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )
                    }}
                >
                    <div className='storeTimingButton'>
                        <button type="submit" className="ant-btn ant-btn-primary rounded" style={{ width: "120px" }}>
                            Save
                        </button>
                    </div>
                </List>
            </Form>

        </div >
    );

}

export default BusinessHours;