// production
export const apiUrl = process.env.REACT_APP_API_URL
export const socketUrl = process.env.REACT_APP_SOCKET_URL
export const customerPortalUrl = process.env.REACT_APP_CUSTOMER_URL
export const mapKey = process.env.REACT_APP_MAP_API_KEY

// development
// export const apiUrl = 'https://api.quickse.in'
// export const apiUrl = 'http://122.186.88.170:8000'
// export const socketUrl = 'ws://122.186.88.170:8000/ws'
// export const customerPortalUrl = 'http://122.186.88.170:3331'
export const loadingAttempts = 2
export const fbAppId = '687988341580190'
export const FilterDateFormat = 'YYYY-MM-DD'
export const DateFormat = 'DD-MM-YYYY HH:mm A'
export const dateFormat = 'do MMMM YYYY'
export const MaxImageUpload = 7
export const RupeeSymbol = '₹ '
export const tabMaxWidth = 1100
export const productName = 'QuickSe'
export const maxCountUpload = 1