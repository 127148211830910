
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}
export function passwordValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || (isNormalInteger(value) && value.length === 6)) {
                return Promise.resolve();
            }
            else if (value.length !== 6) {
                return Promise.reject('Pin must be of 6 digits');
            }
            return Promise.reject('Invalid Pin');
        },
    })
}
export function minimumPurchaseValidator({ getFieldValue }) {
    message.destroy()
    return ({
        validator(_, value) {
            if (!value || (getFieldValue('discountType') === 'PERCENTAGE' || value > getFieldValue('discountAmount'))) {
                return Promise.resolve();
            }
            message.error('Minimum Order Value should be greater than Discount Value')
            return Promise.reject();
        },
    })
}
export function pinCodeValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || parseInt(value).toString().length === 6) {
                return Promise.resolve();
            }
            return Promise.reject('Invalid Pin Code');
        },
    })
}
export function mobileValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || (isNormalInteger(value) && value.length === 10)) {
                return Promise.resolve();
            }
            return Promise.reject('Invalid Mobile no.');
        },
    })
}

export function sellingPriceValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || getFieldValue('mrp') >= value) {
                return Promise.resolve();
            }
            return Promise.reject('Selling price should be less than MRP');
        },
    })
}

export function confirmBox(dispatch, { onOk, message, otp, onOtp, okText, cancelText, title, icon, ...rest }) {

    Modal.confirm({
        onOk: () => {
            onOk && onOk()
        },
        centered: true,
        content: message,
        title: title || 'Confirm',
        icon: icon || <ExclamationCircleOutlined />,
        okText: okText || 'Yes',
        cancelText: cancelText || 'No',
        ...rest
    });
}
export function datePickerRange(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export function getPercentage(mrp, sellingPrice) {
    let percentValue = 100 - sellingPrice / (mrp / 100)
    percentValue = parseFloat(percentValue)
    percentValue = isNaN(percentValue) ? 0 : percentValue.toFixed(1)
    return percentValue;
}

export function downloadJson(obj, filename) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    var a = document.createElement('a')
    a.href = dataStr
    a.setAttribute('download', `${filename}.json`)
    a.click()
}

// component loader

export function componentLoader(lazyComponent, attemptsLeft = 2) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // let us retry after 100 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 100);
            });
    });
}

//IFSC Regex

export const IfscRegex = '^[A-Z]{4}0[A-Z0-9]{6}$'
export const upiRegex = /^[\w.-]+@[\w.-]+$/
export const alphaNumericProductRegex = /^[a-z0-9 ()&\-+,.]+$/i
export const variantValueRegex = /^[a-z0-9 .]+$/i
export const alphaNumericRegex = /^[a-z0-9 ]+$/i
export const storeNameRegex = /^[a-z0-9 '()&]+$/i
export const alphaNumericCapsRegex = /^[A-Z0-9]+$/
export const alphaNumericWithCommasRegex = /^[a-z0-9,\- \/()]+$/i