import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const initialOptions = {
    chart: {
        type: 'column',
        width: 290,
        height: 170
    },
    title: false,
    tooltip: {
        shared: true
    },
    xAxis: {
        categories: [],
        title: {
            enabled: false
        },
        tickInterval: 2,
        labels: {
            autoRotation: false
        }
    },
    yAxis: {
        title: {
            text: 'Sales in INR'
        }
    },
    legend: {
        enabled: false
    },
    series: []
};
const SalesChart = (props) => {
    const { data, labels } = props
    const [options, setOptions] = useState(initialOptions)
    useEffect(() => {
        setOptions(() => ({
            ...initialOptions,
            xAxis: {
                ...initialOptions.xAxis,
                categories: labels
            },
            series: data
        }))
    }, [data, labels])
    return <HighchartsReact highcharts={Highcharts} options={options} />
}
export default SalesChart;
