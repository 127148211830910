import React, { lazy } from 'react'
import _ from 'lodash'
import { Path } from 'path-parser'
import { ReactComponent as OrderIcon } from './assets/orderIcon.svg'
import { ReactComponent as ProductIcon } from './assets/productIcon.svg'
import { ReactComponent as HomeIcon } from './assets/homeIcon.svg'
import { ReactComponent as SettingIcon } from './assets/settingIcon.svg'
import { ReactComponent as DeliveryIcon } from './assets/delivery.svg'
import { ReactComponent as DiscountIcon } from './assets/discount.svg'
import { ReactComponent as UserIcon } from './assets/userIcon.svg'
import { ReactComponent as HelpIcon } from './assets/help.svg'
import { ReactComponent as PrivacyPolicyIcon } from './assets/privacyPolicy.svg'

import { push } from 'connected-react-router'
import { MoneyCollectOutlined, QqCircleFilled, UserOutlined, BankOutlined } from '@ant-design/icons'
import SalesChart from './containers/charts/saleChart'
import { componentLoader } from './utils'
import BusinessHours from './containers/settings/businesshours'

const Home = lazy(() => componentLoader(() => import('./containers/home')))
const AddProduct = lazy(() => componentLoader(() => import('./containers/products/add')))
const Product = lazy(() => componentLoader(() => import('./containers/products')))
const Order = lazy(() => componentLoader(() => import('./containers/order')))
const OrderDetail = lazy(() => componentLoader(() => import('./containers/order/orderDetail')))
const Settings = lazy(() => componentLoader(() => import('./containers/settings')))
const BulkUpload = lazy(() => componentLoader(() => import('./containers/products/bulkUpload')))
const Customers = lazy(() => componentLoader(() => import('./containers/customers')))

const AccountSetting = lazy(() => componentLoader(() => import('./containers/settings/accountSetting')))
const BankAccountSetting = lazy(() => componentLoader(() => import('./containers/settings/bankAccountSettings')))
const PaymentSetting = lazy(() => componentLoader(() => import('./containers/settings/payment')))
const AppSettings = lazy(() => componentLoader(() => import('./containers/settings/appSettings')))
const DeliverySetting = lazy(() => componentLoader(() => import('./containers/settings/deliverySetting')))
const DiscountCodesSetting = lazy(() => componentLoader(() => import('./containers/settings/discountList')))
const MarketingSetting = lazy(() => componentLoader(() => import('./containers/marketing')))
const QrPrintModal = lazy(() => componentLoader(() => import('./components/QrCodeModal/qrPrintModal')))
const HelpSetting = lazy(() => componentLoader(() => import('./containers/settings/helpSetting')))
const PrivacyPolicy = lazy(() => componentLoader(() => import('./containers/settings/privacyPolicy')))
const AdCompaign = lazy(() => componentLoader(() => import('./containers/settings/adcompaign')))

const menu = [
  {
    'path': '/home',
    'name': 'Home',
    'icon': <HomeIcon />,
    'key': 'home',
    'component': <Home />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/orders',
    'name': 'Orders',
    'icon': <OrderIcon />,
    'key': 'order',
    'component': <Order />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/orderDetails/:id',
    'name': 'Orders Details',
    'icon': <OrderIcon />,
    'key': 'orderDetails',
    'dontShowOnMenu': true,
    'goBack': true,
    'component': <OrderDetail />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/products',
    'name': 'Products',
    'icon': <ProductIcon />,
    'key': 'productsList',
    'component': <Product />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addProduct',
    'name': 'Add Product',
    'icon': <ProductIcon />,
    'key': 'addProduct',
    'goBack': true,
    'component': <AddProduct />,
    'dontShowOnMenu': true,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addProduct/:id',
    'name': 'Add Product',
    'icon': <ProductIcon />,
    'key': 'addProductVariant',
    'goBack': true,
    'component': <AddProduct isVariant={true} />,
    'dontShowOnMenu': true,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/updateProduct/:id',
    'name': 'Edit Product',
    'icon': <ProductIcon />,
    'key': 'updateProduct',
    'goBack': true,
    'component': <AddProduct />,
    'dontShowOnMenu': true,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/services',
    'name': 'Services',
    'icon': <SettingIcon />,
    'key': 'services',
    'component': <Settings />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/bulkUpload',
    'name': 'Product Bulk Upload',
    'icon': <SettingIcon />,
    'key': 'bulkUpload',
    'goBack': true,
    'dontShowOnMenu': true,
    'component': <BulkUpload />,
    'authority': [
      'user' 
    ]
  },
  {
    'path': '/services/customersInformation',
    'name': 'Customers Information',
    'icon': <UserOutlined />,
    'key': 'customersInformation',
    'goBack': true,
    'component': <Customers />,
    'dontShowOnMenu': true,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/services/store',
    'name': 'Store Information',
    'dontShowOnMenu': true,
    key: 'store',
    'goBack': true,
    icon: <UserIcon />,
    component: <AccountSetting title='Store Information' />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/bankInformation',
    'name': 'Bank Information',
    key: 'bankInformation',
    'goBack': true,
    icon: <BankOutlined />,
    dontShowOnMenu: true,
    component: <BankAccountSetting title='Bank Information' />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/transactionInformation',
    'name': 'Transaction Information',
    key: 'transactionInformation',
    'goBack': true,
    icon: <MoneyCollectOutlined />,
    dontShowOnMenu: true,
    component: <PaymentSetting title='Transaction Information' />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/app',
    'name': 'App Settings',
    key: 'app',
    'goBack': true,
    icon: <UserIcon />,
    dontShowOnMenu: true,
    component: <AppSettings title='App Settings' />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/delivery',
    'name': 'Delivery Settings',
    key: 'delivery',
    'goBack': true,
    icon: <DeliveryIcon />,
    dontShowOnMenu: true,
    component: <DeliverySetting title='Delivery Settings' />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/promoCodes',
    'name': 'Promo Codes',
    key: 'promoCodes',
    'goBack': true,
    icon: <DiscountIcon />,
    dontShowOnMenu: true,
    component: <DiscountCodesSetting title={'Promo Codes'} />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/digitalAds',
    'name': 'Digital Ads',
    key: 'digitalAds',
    'goBack': true,
    withoutWrapper: true,
    dontShowOnMenu: true,
    icon: <SalesChart />,
    component: <MarketingSetting title={'Digital Ads'} />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/qr',
    'name': 'QR Code',
    key: 'qr',
    'goBack': true,
    // withoutWrapper: true,
    dontShowOnMenu: true,
    icon: <QqCircleFilled />,
    component: <QrPrintModal title={'QR Code'} />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/support',
    'name': 'Help & Support',
    key: 'support',
    'goBack': true,
    icon: <HelpIcon />,
    dontShowOnMenu: true,
    component: <HelpSetting title="FAQ’s" />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/termsAndconditions',
    'name': 'Terms & Conditions',
    key: 'termsConditions',
    'goBack': true,
    icon: <PrivacyPolicyIcon />,
    dontShowOnMenu: true,
    component: <PrivacyPolicy title="Terms & Conditions" />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/businesshours',
    'name': 'Business Hours',
    key: 'businesshours',
    'goBack': true,
    icon: <PrivacyPolicyIcon />,
    dontShowOnMenu: true,
    component: <BusinessHours title="Business Hours" />,
    'authority': [
      'user'
    ],
  },
  {
    'path': '/services/adcompaign',
    'name': 'Ads Campaign',
    key: 'adcompaign',
    'goBack': true,
    icon: <PrivacyPolicyIcon />,
    dontShowOnMenu: true,
    component: <AdCompaign title="Ads Compaign" />,
    'authority': [
      'user'
    ],
  },
]

// utils for path

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params = {}) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)
    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)
  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}


export default menu;